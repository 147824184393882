import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import lng_en from "./translations/en/translation.json";
import lng_sv from "./translations/sv/translation.json";
import lng_dk from './translations/dk/translation.json';
import lng_de from './translations/de/translation.json';
import lng_no from './translations/no/translation.json';
import lng_fi from './translations/fi/translation.json';

const resources = {
    en: {
        translation: lng_en
    },
    sv: {
        translation: lng_sv
    },
    dk: {
        translation: lng_dk
    },
    de: {
        translation: lng_de
    },
    no: {
        translation: lng_no
    },
    fi: {
        translation: lng_fi
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "sv",

        interpolation: {
            escapeValue: false
        }
});

export default i18n;