import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from "../../components/Context/cart/CartContext";
import Breadcrumbs from '../../components/Breadcrumbs/';
import Quantity from "../../pages/Product/quantity";
import { formatNumber } from "../../components/Utils/numbers";
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { ProductLoader} from '../../components/ContentLoader';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { alertLoadingError } from '../../components/Confirm';

function Product(props) {
    const { callApi } = useAxios();
    const { inProgress } = useMsal();
    const { t } = useTranslation()
    let { productId } = useParams();
    const { addProduct } = useContext(CartContext)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [addingProduct, setAddingProduct] = useState(false)
    const [product, setProduct] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])

    useEffect(() => {
        const defaultBreadcrumbs = [
            {
                id: '/order-add',
                label: t('breadcrumbs.add-new-order')
            },
            {
                id: '/shop/category/471B9F83-9332-4770-9AE9-33B3C8',
                label: t('breadcrumbs.other-articles')
            }
        ];

        if (product.length === 0 && inProgress === InteractionStatus.None) {

            callApi({
                method: 'get',
                url: `/api/order/productDetails?itemNo=${productId}`
            })
                .then(response => {
                    let o = response.data

                    //Rename JSON keys
                    const product = {
                        image: o.ImageUrl,
                        title: o.Description,
                        sku: o.ItemId,
                        id: o.ItemId,
                        price: o.UnitPrice
                    };

                    defaultBreadcrumbs.push({
                        id: '',
                        label: o.Description
                    })

                    const storedBreadcrumbs = localStorage.getItem('breadcrumbs') ? JSON.parse(localStorage.getItem('breadcrumbs')) : null;

                    if(storedBreadcrumbs) {
                        storedBreadcrumbs.push({
                            id: '',
                            label: o.Description
                        })
                    }

                    setProduct(product)
                    setBreadcrumbs(storedBreadcrumbs ? storedBreadcrumbs : defaultBreadcrumbs)
                    setLoading(false)
                })
                .catch(error => {
                    setLoading(false)
                    setError(true)
                    alertLoadingError(t)
                    console.error(error) // eslint-disable-line no-console
                })
        }

    }, [inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

    product.addQty = 1;

    const updateQuantity = (quantity) => {
        product.addQty = quantity;
    };

    const addToCart = () => {
        setAddingProduct(true)
        addProduct(product);
        setTimeout(function() {
            setAddingProduct(false)
        }, 700);
    };

    const Product = () => {
        return (
            <div className="product">
                <div className="product-figure">
                    <img src={product.image} alt={product.title} />
                </div>
                <div className="product-details">
                    <div className="product-description">
                        <h2>{product.title}</h2>
                        <p className="product-sku">{product.sku}</p>
                        <p className="product-price">{formatNumber(product.price)}</p>
                    </div>
                    <div className="product-action">
                        <Quantity updateQuantity={updateQuantity} />
                        <button type="button" className={`button-secondary add-to-cart-btn${addingProduct ? ' add-to-cart-added' : ''}`} onClick={addToCart} disabled={addingProduct}>
                            <span className="btn-text">{t('product.add-to-cart')}</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className="main-content main-content-shop">
            {loading &&
                <ProductLoader />
            }
            {!loading && !error &&
                <>
                    <Breadcrumbs items={breadcrumbs} />
                    <Product />
                </>
            }
        </div>
    );
}
export default Product;