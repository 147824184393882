import axios from 'axios';
import React, { useState, useEffect } from 'react';
import '../../../../i18n'
import { useTranslation } from 'react-i18next';


export default function NewsOverlay() {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    const [post, setPost] = useState(null);


    useEffect(() => {
        try {
            async function fetchData() {
                let res = await axios({
                    method: 'get',
                    baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
                    url: '/rest-api/mediaApi/articles',
                    params: {
                        market: 'sv-SE',
                        isFlagged: true,
                        limit: 1,
                        sort: true,
                    }
                });
                
                const post = res.data[0];
                const showNews = await showFlaggedNews(post);

                if (showNews && post.id.length > 0) {
                    setIsVisible(true);
                    setPost(post);
                    localStorage.setItem(
                        'viewed',
                        JSON.stringify({
                            time: Date.now(),
                            id: post.id,
                        })
                    );
                }
            }
            fetchData();
        } catch (err) {
            console.log("Failed to fetch flagged news")
        }


    }, [setPost]);

    const closeOverlay = () => {
        setIsVisible(!isVisible);
    };

    /**
     * Check if flagged news should be displayed for the user
     * @param {*} post 
     * @returns Boolean
     */
    const showFlaggedNews = async (post) => {
        const viewed = await JSON.parse(localStorage.getItem('viewed'));
        const currentTime = Date.now();
        const hours = 168; // 168 = 7 days
        const timeLimit = 60 * 60 * 1000 * hours;
        // If there is a viewd post, check if it should be displayed again.
        if (viewed) {
            const timeDifference = currentTime - viewed.time;
            if (post.id === viewed.id && timeDifference < timeLimit) {
                return false;
            }
        }

        return true;
    };

    return (
        <>{(isVisible && post) &&
            <div className="news-overlay-background">
                <div className="news-overlay-container">

                    <div className="news-overlay-card">
                        <a href={'/news/' + post?.id} onClick={closeOverlay}>
                            <img
                                alt="Not found"
                                src={post?.image}
                                className="card-img"
                            />
                            <h3 className="card-title">{post?.title}</h3>
                            <p className="card-abstract">{post?.abstract}</p>
                            <br></br>
                            <h5>{t('news.news-overlay.read-more')}</h5>
                        </a>
                    </div>

                    <button className="btn close-btn" onClick={closeOverlay}>
                        {t('news.news-overlay.close')}
                    </button>
                </div>
            </div>
        }
        </>

    );
}
